import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import { SafeHtmlParser } from "../components/safeHtmlParser";

const BlogPostTemplate = ({ data: { post, site } }) => {
  const siteUrl = site.siteMetadata.siteUrl;
  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "News",
        item: {
          url: `${siteUrl}/news`,
          id: `${siteUrl}/news`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: `${post.title}`,
        item: {
          url: `${siteUrl}/news/${post.slug}`,
          id: `${siteUrl}/news/${post.slug}`,
        },
      },
    ],
  };
  return (
    <Layout pageSettings={{ showFooterAbstractImage: true }}>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title={`${post.title} | Eastbourne Carnival`}
        description={parse(post.excerpt)}
        openGraph={{
          url: `${siteUrl}/news/${post.slug}`,
          title: `${post.title} | Eastbourne Carnival`,
          description: parse(post.excerpt),
          type: "article",
          images: [
            {
              url: `${post.articleFields.featureImage.localFile.publicURL}`,
              width: `${post.articleFields.featureImage.localFile.childImageSharp.original.width}`,
              height: `${post.articleFields.featureImage.localFile.childImageSharp.original.height}`,
              alt: `${post.articleFields.featureImage.altText}`,
            },
          ],
        }}
      />

      <div className="bg-secondary pt-8 pb-7">
        <Container>
          <Row className="align-items-lg-end justify-content-center">
            <Col className="pl-0 ps-md-3" xl={8}>
              <GatsbyImage
                className="h-md-100 h-lg-auto w-100 rounded"
                image={
                  post?.articleFields?.featureImage?.localFile?.childImageSharp
                    ?.gatsbyImageData
                }
                alt={post?.title || "Section Image"}
                style={{ maxHeight: "550px" }}
              />
              <div className="px-md-4">
                <div className="d-flex flex-column flex-md-row justify-content-between my-4">
                  <div className="me-2">
                    <h1 className="fs-3 mb-0 text-purple-dark">
                      {post?.title}
                    </h1>
                    <span className="text-purple-dark fw-medium">
                      {post?.author.node?.name}
                    </span>
                  </div>

                  {post?.dateGmt && (
                    <span className="fw-bold text-purple-dark text-nowrap">
                      {post?.dateGmt}
                    </span>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="py-9">
        <Row className="justify-content-center">
          <Col lg={10} xl={6}>
            <div className="content-light-black">
              <SafeHtmlParser htmlContent={post?.articleFields?.content} />
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostById($id: String!) {
    post: wpArticle(id: { eq: $id }) {
      id
      excerpt
      title
      dateGmt(formatString: "MMMM DD, YYYY")
      author {
        node {
          name
        }
      }
      articleFields {
        featureImage {
          altText
          localFile {
            publicURL
            childImageSharp {
              original {
                height
                width
              }
              gatsbyImageData(
                quality: 100
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
          }
        }
        content
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
